.game-container {
  
  .game-banner {
    width: 100%;
    background-color: #E1E8ED;
    padding: 20;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .game-title-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    span {
      padding: 0;
      margin: 0;
      h1 {
        padding: 0;
        margin: 0;
        padding-bottom: 10;
      }
    }
  }

  .game-title-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      margin-left: 10;
      width: 30;
      height: 30;
    }
  }

  .game-point-display {
    min-width: 100;
    font-family: blenderProBold;
    font-size: 64;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10;
    padding-right: 10;
  }

  .game-point-display.game-win {
    background-color: #0A6640;
  }

  .game-point-display.game-loss {
    background-color: #A82A2A;
  }

  .slam-banner {
    background-color: #0A6640;
    display: flex;
    justify-content: center;
    align-items: center;  
    padding: 5px;
    h4 {
      color: white;
      padding: 0;
      margin: 0;
    }
  }

  .game-data-container {
    background-color: #F5F8FA;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .bidder-team-container, .opposition-team-container {
    min-width: 300px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .player-container {
    background-color: white;
    margin: 10;
    padding-top: 10;
    padding-bottom: 10;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .winner-container {
    border-radius: 15px;
    border-style: solid;
    border-width: 3px;
    border-color: #0A6640;
  }

  .loser-container {
    border-radius: 15px;
    border-style: solid;
    border-width: 3px;
    border-color: #A82A2A;
  }
}