.bp3-navbar-heading {
  .brand-link {
    font-size: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 10;
    }
  }
}

.pt-app {
  overflow-x: auto;
  padding-top: 50px;
  > div {
    margin: 0 auto;
    width: 100%;
  }
}

.tarot-navbar {
  z-index: 20;
}

.brand-link {
  font-family: blenderProMedium;
}

.hide-on-small {
  font-family: dinNextLight;
}