.card-group-title {
  font-family: blenderProMediumItalic;
  font-size: 16;
  paint-order: stroke;
  stroke-width: 2px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke: white;
}

.card-group-rect {
  stroke: white;
  fill: none;
}
