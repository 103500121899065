.game-form {
  margin-bottom: 20px;
  padding: 0px;
  .card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    div.bp3-card {
      h3 {
        margin-bottom: 20px;
      }
      @media (max-width: 400px) {
        width: 100%;
      }
      @media (max-width:700px) and (min-width:400px) {
        width: 90%;
      }
      @media (min-width: 700px) {
        width: 40%;
      }
    }
  }
  .hide-called-self {
    display: none !important;
  }
}

.player-select-bar {
  display: flex;
  justify-content: center;
  .bp3-button {
    @media (max-width: 400px) {
      flex-basis: 80px;
    }
    @media (max-width:700px) and (min-width:400px) {
      flex-basis: 100px;
    }
    @media (min-width: 700px) {
      flex-basis: 200px;
    }
  }
}

.enter-score-button-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .enter-score-button {
    @media (max-width: 700px) {
      width: 100%;
    }
    @media (min-width: 700px) {
      width: 600px;
    }
  }
}

.player-button {
  font-family: dinNextRegular;
}