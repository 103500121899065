.player-tag:not(:last-child) {
  margin-right: 5px;
}

.new-room-button {
  margin-left: 10px;
}

.lobby-tab-container {
  margin-top: 20px;
}

.no-rooms-container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  width          : 100%;
  height         : 200;
}

.lobby-name-input {
  margin-bottom: 20px;
}
