.message-input-area {
  margin-top: 10px;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .message-input {
    height: 100%;
    width: 100%;
    outline: none;
    resize: none;
    border: 1px solid #202b33;
    background-color: #202b33;
    color: #f5f8fa;
    font-family: dinNextRegular;
  }

  .message-send {
    height: 100%;
    outline: none;
  }
}
