.player-row {
  font-family: dinNextBold;
  font-size: 16px;
  margin-bottom: 5px;

  *:not(:last-child) {
    margin-right: 10px;
  }
  
  .player-name {
    font-family: dinNextRegular;
  }

  .bot-checkbox {
    display: inline;
  }
}
