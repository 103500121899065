@import "~@blueprintjs/core/lib/scss/variables";

.bot-card {
  margin-right: 10px;
  margin-top: 10px;
  width: 250px;

  .bot-card-content {
    display: flex;
    flex-direction: row;

    .bot-icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bot-card-details {
      display: flex;
      flex-direction: column;

      .bot-name {
        font-family: dinNextRegular;
        font-size: 20px;
      }

      .bot-type {
        font-family: dinNextItalic;
        color: $gray1;
      }
    }
  }
}
