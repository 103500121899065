.score-table {
  white-space: nowrap;
  width: 100%;
  
  thead tr th {
    background-color: #EBF1F5;
  }

  td.rank-row {
    display: flex;
    justify-content: center;
  }
}