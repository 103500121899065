.tarot-text-input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tarot-text-input-content, .tarot-text-input-group {
  width: 100%;
}

.tarot-select {
  width: 100;
  select {
    width: 100%;
  }
}

.tarot-select-input-group > .bp3-form-helper-text, .tarot-select-group {
  display: flex;
  justify-content: center;
}

.tarot-points-input-group.bp3-form-group {
  display: flex;
  flex-direction: column;
  align-items: center;

  .tarot-points-input-content {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .tarot-points-input {
      width: 100px;
      margin-right: 28px;
      margin-left: 10px;
    }
  }
}