.results-container {
  .results-header {
    display        : flex;
    flex-direction : row;
    justify-content: space-around;
    align-items    : center;
  }

  .results-tabs-container {
    background-color: #FFFFFF;
    padding         : 10;

    .bp3-tabs {
      background-color: #FFFFFF;
    }
  }

  .no-results-container {
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    height         : 200;
  }
}