.play-sidebar {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #394b59;
  border-left: 1px solid #394b59;
  align-items: center;

  .sidebar-header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .header-image {
      width: 180px;
      height: 180px;
      object-fit: cover;
      overflow-y: hidden;
      margin-bottom: 10px;
    }

    .lobby-button {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }

  .toggle-button-group {
    margin-bottom: 10px;
    width: 200px;
  }
  
  .list-container {
    background-color: #202b33;
    border-radius: 8px;
    padding: 5px;
    overflow-y: scroll;
    width: 100%;
    flex-grow: 1;
    border: 1px solid #202b33;
    color: #f5f8fa;
    overflow-wrap: break-word;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #182026;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
      background: #30404d;
      border-radius: 20px;
    }
  }
}
