.player-banner {
  width: 100%;
  background-color: #E1E8ED;
  padding: 20;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.player-title-container {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex: 1;
  span {
    padding: 0;
    margin: 0;
    h1 {
      padding: 0;
      margin: 0;
      padding-bottom: 10;
    }
  }
}

.player-point-display {
  min-width: 100;
  font-family: blenderProBold;
  font-size: 64;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10;
  padding-right: 10;
}

.player-point-display.game-win {
  background-color: #0A6640;
}

.player-point-display.game-loss {
  background-color: #A82A2A;
}

.player-point-display.none {
  background-color: #CED9E0;
}
