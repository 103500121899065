@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import '~plottable/plottable.css';
@import './containers/addPlayer/AddPlayerContainer.scss';
@import './containers/app/AppContainer.scss';
@import './containers/home/HomeContainer.scss';
@import './containers/enter/EnterContainer.scss';
@import './containers/login/LoginContainer.scss';
@import './containers/game/GameContainer.scss';
@import './containers/recent/RecentContainer.scss';
@import './containers/records/RecordsContainer.scss';
@import './containers/results/ResultsContainer.scss';
@import './containers/search/SearchContainer.scss';
@import './containers/search/SearchResultsContainer.scss';
@import './containers/player/PlayerContainer.scss';
@import './containers/player/PlayerGraphTab.scss';
@import './containers/player/PlayerBanner.scss';
@import './containers/gamePlayerInput/GamePlayerInput.scss';
@import './containers/dataTabs/deltas/DeltaTable.scss';
@import './containers/dataTabs/streaks/AllStreaksTab.scss';
@import './containers/dataTabs/bids/BidsTab.scss';
@import './containers/tarothon/TarothonsContainer.scss';
@import './containers/tarothon/TarothonContainer.scss';
@import './containers/pointFlow/PointFlow.scss';
@import './components/spinnerOverlay/SpinnerOverlay.scss';
@import './components/gameTable/GameTable.scss';
@import './components/forms/GameForm.scss';
@import './components/forms/AddPlayerForm.scss';
@import './components/forms/PlayerSelect.scss';
@import './components/toaster/Toaster.scss';
@import './components/results/ResultsGraphContainer.scss';
@import './components/graphs/ResultsGraph.scss';
@import './components/forms/Elements.scss';
@import './components/scoreTable/ScoreTable.scss';
@import './components/deltaIcon/DeltaIcon.scss';
@import './components/tarothon/TarothonForm.scss';
@import './components/monthPicker/MonthPicker.scss';
@import './components/search/SearchForm.scss';

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  p {
    font-size: 14px;
    color: #182026;
  }
}

@media (max-width: 700px) {
  .hide-on-small {
    display: none;
  }
  .pt-dialog.pt-overlay-content {
    width: 95%;
  }
}

p, span.text, .bp3-label, td, li {
  font-family: dinNextRegular;
}

span.bold {
  font-family: blenderProBold;
}

span.underline {
  text-decoration: underline;
}

h1, h2, h3, h4, h5 {
  font-family: blenderProBold;
}

h6 {
  font-family: blenderProMediumItalic;
}

th {
  font-family: blenderProBold;
  font-size: 18;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: blenderProBold;
  margin-bottom: 20;
  color: #F5F8FA;
  @media (max-width: 700px) {
    font-size: 48px;
  }
  @media (min-width: 700px) {
    font-size: 82px;
  }
  padding-bottom: 0;
  margin-bottom: 0;
}

.logo.dark {
  color: black;
}

.subtitle {
  font-size: 12;
  font-family: dinNextItalic;
  margin-bottom: 50;
  color: #F5F8FA;
}

.subtitle.dark {
  color: black;
}

.page-container {
  @media (max-width: 700px) {
    margin: 10 auto;
    width: 90%;
  }
  @media (min-width: 700px) {
    margin: 60 auto;
    width: 80%;
  }
}

.table-container {
  overflow-x: scroll;
  -ms-overflow-style:none;
  overflow:-moz-scrollbars-none;
}
.table-container::-webkit-scrollbar{width:0px}

.bp3-tab-list {
  overflow-x: scroll;
  -ms-overflow-style:none;
  overflow:-moz-scrollbars-none;
}
.bp3-tab-list::-webkit-scrollbar{width:0px}

.bp3-input {
  -webkit-appearance: none;
}

.player-stats-table {
  white-space: nowrap;
  width: 100%;

  thead tr th {
    background-color: #EBF1F5;
  }

  td.averages {
    font-family: blenderProBold;
    font-weight: bold;
    font-size: 18px;
  }

  tbody tr td {
    background-color: white;
    
    &.not-applicable {
      background-color: #CED9E0;
    }
    
    &.greater {
      background-color: #3DCC91;
    }
    
    &.lesser {
      background-color: #FF7373;
    }
  }
}

th.sortable {
  cursor: pointer;
}

.bp3-tab {
  font-family: blenderProBold;
  font-size: 18;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
