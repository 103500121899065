.pt-login {
  background-color: #293742;
  margin: 0px auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-container {
    max-width: 352;
    display: flex;
    flex-direction: column;
    align-items: center;
    .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      .bp3-input {
        font-family: blenderProMedium;
        width: 200;
        color: #F5F8FA;
        background-color: #182026;
        margin-bottom: 10;
      }
      $placeholderColor: #BFCCD6;
      .bp3-input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: $placeholderColor;
      }
      .bp3-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $placeholderColor;
        opacity: 1;
      }
      .bp3-input::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $placeholderColor;
        opacity: 1;
      }
      .bp3-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $placeholderColor;
      }
      .bp3-input::-ms-input-placeholder { /* Microsoft Edge */
        color: $placeholderColor;
      }

      .bp3-button {
        width: 200;
        font-family: blenderProBold;
        font-size: 18;
      }
    }
  }
}