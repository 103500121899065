.player-graph-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.player-graph-container {
  width: 100%;
  height: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
