.add-player-form {

  .bp3-add-player-input {
    @media (max-width: 700px) {
      width: 90%;
    }
    @media (min-width: 700px) {
      width: 80%;
    }
  }

  .add-player-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .add-player-button {
      width: 100;
    }
  }
  
  .add-player-score-button {
    margin-top: 20;
  }
}