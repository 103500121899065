.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    font-family: blenderProBold;
    margin-top: 20;
    margin-bottom: 20;
    color: #F5F8FA;
    @media (max-width: 700px) {
      font-size: 48px;
    }
    @media (min-width: 700px) {
      font-size: 82px;
    }
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .menu {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10;
      .bp3-button {
        width: 200px;
      }
    }
  }

  .info-overlay {
    padding: 5px;
    font-style: italic;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    span.callout {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 20px;

      .github-logo, .slack-logo {
        margin-left: 5px;
        margin-right: 2px;
        width: 16px;
        height: 16px;
      }
    }
  }
}