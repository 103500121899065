.bot-container {

  .bot-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .bot-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
